header {
    display: flex;
    justify-content: space-between;
}

#logo {
    font-size: 2rem;
}

#page-links {
    display: flex;
    justify-content: space-between;
    width: 10vw;
}

img {
    min-width: 200px;
}

@media(max-width: 1000px) {
    header {
        width: 58%;
    }
    #logo {
        font-size: 1.5rem;
    }
    #page-links > * {
        margin: .5em;
    }
}