#cv {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 3em;
    color: white;
    height: 70vh;
}
.left {
    width: 60%;
    text-align: justify;
}

.left > h2 {
    text-align: center;
}

.left > p {
    border-left: 1px solid white;
    border-right: 1px solid white;
    padding: 1em;
}

@media(max-width: 1000px) {
    #cv {
        display: inline-block;
    }
    .left {
        width: 100%;
    }
    .right > img {
        margin: 0 50%;
    }
}