.project {
    display: flex;
    justify-content: center;
    align-items: last baseline;
    margin: 2em 0;
    flex-wrap: wrap;
}

.details {
    width: 60%;
}

.details > img {
    margin: 1em;
    width: 80%;
}

.link {
  text-transform: uppercase;
  border: 1px solid white;
  padding: .5em 1em;
  background-color: transparent;
  color: white;
}

.desc {
    border: 3px solid white;
    border-radius: 1em;
}

h3 {
    font-weight: normal;
    font-size: 2rem;
    text-align: center;
    border-bottom: 1px solid white;
    padding: 1em;
    margin: 1em;
}

ul {
    padding: 1em 2em;
}

li {
    list-style: none;
}

img {
    width: 50%;
}

@media(max-width: 1000px) {
    .details {
        width: 100%;
    }
    #projects {
        display: inline-block;
    }
    img {
        width: 300px;
        margin: 2em 12%;
    }
}